
export const Rebuy = {

  init: () => {

    Rebuy.events();

  },

  state: {},

  settings: {

    rebuy_excluded_properties: ["_gcp_selected_card_image", "_gcp_video_id", "_gcp_thumbnail_image", "_gcp_locale", "_gcp_delivery_date", "_gcp_conversion_rate", "_gcp_delivery_time", "_gcp_delivery_timezone"]

  },

  events: () => {

    document.addEventListener('rebuy:smartcart.show', (e) => {
      Rebuy.handleShow(e);
    });

    document.addEventListener('rebuy:smartcart.ready', (e) => {
      Rebuy.handleShow(e);
      Rebuy.observeMutations();
    });

    document.addEventListener('rebuy:smartcart.init', (e) => {
      Rebuy.handleShow(e);
    });

    document.addEventListener('rebuy:smartcart.product-removed', (e) => {
      Rebuy.handleShow(e);
    });


  },

  observeMutations: () => {

    // Select the node that will be observed for mutations
    const targetNode = document.querySelector("[data-smartcart-items]");
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: false };

    if (targetNode === null) {
      return; // the cart is empty
    }

    // Callback function to execute when mutations are observed
    const callback = (mutationList, observer) => {

      if (typeof(Rebuy.state.smartcartEvent) != 'undefined') {
        Rebuy.handleShow(Rebuy.state.smartcartEvent);
      }

    };

    // Create an observer instance linked to the callback function
    Rebuy.state.observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    Rebuy.state.observer.observe(targetNode, config);

  },

  /**
   * Starts the process for adding Gift Card Pro elements into the rebuy smartcart
   */
  handleShow: (e) => {

    let smartcart;

    Rebuy.state.smartcartEvent = e;

    if (typeof(e) != 'object' || typeof(e.detail) != 'object' || typeof(e.detail.smartcart) != 'object') {
      console.warn("An invalid rebuy event was thrown.", e);
      return;
    }

    smartcart = e.detail.smartcart;

    Rebuy.modifyElements(smartcart);



  },

  /**
   * Gets the elements for the products in the smartcart
   *
   * @returns {Array}
   */
  getElements: () => {

    const elements = document.querySelectorAll(".rebuy-cart__flyout-item");

    if (!elements) {
      console.warn("No rebuy cart elements present");
      return [];
    }

    return elements;

  },

  /**
   * Modifies an array of product elements to include the gift card images and GCP line item properties that are not in the excludedProperties array.
   *
   * @param {Array} cartItems
   */
  modifyElements: (smartcart) => {

    const cartItems          = smartcart.items();
    const cartItemElements   = Rebuy.getElements();

    if (!Array.isArray(cartItems)) {
      console.warn("An invalid rebuy event was thrown. Cart items is not an array", cartItems);
      return;
    }

    if (cartItemElements.length <= 0) {
      console.warn("There are no cart items present.", cartItems);
      return;
    }

    if (cartItems.length !== cartItemElements.length) {
      console.warn("Cart elements do not match cart items.", cartItems, cartItemElements);
      return;
    }

    cartItems.forEach((item, i) => {

      const lp         = item.properties || {};
      const properties = Object.entries(lp);

      if (typeof(cartItemElements[i]) != 'object') {
        return false;
      }

      if (Object.keys(lp).find(e => e.includes("_gcp")) != null) {
        Rebuy.setLineItemProperties(cartItemElements[i], properties);
        Rebuy.setCartItemImage(cartItemElements[i], lp);
      } else if (cartItemElements[i].querySelector(".gcp__card-details") !== null){
        cartItemElements[i].querySelector(".gcp__card-details").remove();
      }

    });

  },

  /**
   * Adds GCP line item properties to a given smartcart item element
   * @param {Object} currentEl
   * @param {Object} properties
   */
  setLineItemProperties: (currentEl, properties) => {

    const variantName        = currentEl.querySelector(".rebuy-cart__flyout-item-variant-title");
    const lineItemProperties = document.createElement("ul");

    lineItemProperties.className = "gcp__card-details";

    properties.forEach( ([key, value]) => {

      if (Rebuy.settings.rebuy_excluded_properties.includes(key) || value === null || value === "") {
        return true;
      }

      // Build list item and append to the ul element
      lineItemProperties.appendChild(
        Rebuy.buildLineItemPropertyElement(key, value, lineItemProperties)
      );

    });

    // if the element doesn't exist we insert it, if not, we replace it.
    if(currentEl.querySelector(".gcp__card-details") === null){
      if (variantName) variantName.after(lineItemProperties);
      return;
    }

    currentEl.querySelector(".gcp__card-details").replaceWith(lineItemProperties);

  },

  /**
   * Builds an element for a line item propertyList
   * @param {String} key
   * @param {String} value
   * @returns {Object}
   */
  buildLineItemPropertyElement: (key, value) => {

    var name    = key.slice(5);     // Remove _gcp_
    let className;
    const words = name.split("_");
    const el    = document.createElement("li");

    className = "gcp__" + words.join("-");

    words.map( (word, index) => {
      words[index] = word.charAt(0).toUpperCase() + word.slice(1);
    });

    name = words.join(" ");

    el.className = className;
    el.innerHTML = name + ": " + value;

    return el;
  },

  /**
   * changes the first img tag within the given element to the selected card image line item propertyList
   * @param {Object} currentEl
   * @param {Object} propertyList
   */
  setCartItemImage: (currentEl, propertyList) => {

    const imgSrc = propertyList._gcp_selected_card_image;

    if (typeof(imgSrc) !== 'string' ) {
      return;
    }

    currentEl.querySelector("img").src = imgSrc;

  }

};
